// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const config = require('../assets/config/config-dev.json');

export const environment = {
  production: config.production,
  version: '1.3.8TEST',
  versionBE: '1.0.0',
  releaseDate: '2025-02-10',
  restBaseUrl: config.apiBaseUrl,
  rows: 15,
  maxTablePage: 10
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
