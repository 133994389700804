import { Injectable } from '@angular/core';
import { IS1SearchParams, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ICartsSearchParams extends IS1SearchParams {
  idCompany: number;
  idRegion?: string;
  idSite?: string;
  status?: string;
  codCustomer?: string;
  codDevice?: string;
  idTessera?: string;
  tsStartFrom?: number;
  tsStartTo?: number;
}

export interface ISettingsSearchParams extends IS1SearchParams {
  idCompany: number,
  idRegion: string,
  idSite: string
}

export interface ISettingsPaginated {
  settings: ISetting[],
  total: number
}
export interface ISetting {
  id?: number,
  idCompany: number,
  nameCompany?: string,
  idRegion: string,
  nameRegion?: string,
  idSite: string,
  nameSite?: string,
  code?: string,
  value: string
}

export interface ICartsPaginated {
  carts: ICart[],
  total: number
}

export interface ICart {
  items: ICartItem[];
  devices: ICartDevice[];
  id: number;
  idCompany?: number;
  codCustomer?: string;
  codeDesk?: string;
  discountAmount?: number;
  grossAmount?: number;
  nameCompany?: string;
  nameRegion?: string;
  nameSite?: string;
  partialVerification?: string | any;
  paymentAmount?: number;
  quantity?: number;
  status?: string;
  taxAmount?: number;
  totalVerification?: string | any;
  tsEnd?: number;
  tsStart?: number;
}

export interface ICartDevice {
  codeCustomerDevice: string,
  codeUnitShop: string,
  id: number,
  idCart: number,
  tsEnd: number,
  tsStart: number,
}

export interface ICartItem {
  quantity: number,
  idCartItem?: number,
  descriptionItem?: string,
  eanCodeItem?: string,
  grossAmount?: number,
  taxAmount?: number,
  discountAmount?: number,
  paymentAmount?: number,
  descriptionPromotion?: string,
  tsScan?: number,
  partialVerificationQuantity?: number,
  partialVerificationResult?: string,
  totalVerificationQuantity?: number,
  totalVerificationResult?: string,
  totalVerificationGrossAmount?: number,
  totalVerificationTaxAmount?: number,
  totalVerificationDiscountAmount?: number,
  totalVerificationPaymentAmount?: number
}

export interface IDashboardSearchParams {
  idCompany: string;
  idRegion: string;
  idSite: string;
  tsStart: number;
  tsEnd: number;
}

@Injectable({
  providedIn: 'root'
})
export class CartsService {

  private path = "/carts"; 
  private pathDashboard = "/shop/dashboard"
  
  private pathCartsInfo = "/carts-stat";
  private pathSitesCartsStatusInfo = "/carts-status-sites-stat";
  private pathSitesCartsRevenueInfo = "/carts-revenue-sites-stat";
  private pathDaysCartsCounterInfo = "/carts-counter-days-stat";
  private pathDaysCartsRevenueInfo = "/carts-revenue-days-stat";
  
  private pathPartialRescansInfo = "/rescans-partial-stat";
  private pathTotalRescansInfo = "/rescans-total-stat";
  private pathSitesRescansInfo = "/rescans-sites-stat";
  private pathDaysRescansInfo = "/rescans-counter-days-stat";
  
  private pathSettings = "/settings";
  private pathSettingEdit = "/setting";

  constructor(private s1HttpClient: S1HttpClientService) { }

  getCarts(parameters?: ICartsSearchParams, ui: boolean = true): Observable<ICartsPaginated> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => { return { carts: response.data.results, total: response.data.total } })
    )
  }

  downloadCartsExport(parameters?: ICartsSearchParams, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.download(`${this.path}/export`, parameters, ui).pipe(
      map(file => {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
      })
    );
  }

  getCart(cartId: number, ui: boolean = false): Observable<ICart> {
    return this.s1HttpClient.get(this.path + "/" + cartId, {}, ui).pipe(
        map(response => response.data)
    )
  }

  changeDevice(id: number) {
    return this.s1HttpClient.post(this.path + '/' + id + '/change-device', false).pipe(
      map(response => response.data)
    )
  }

  cancelCart(id: number) {
    return this.s1HttpClient.post(this.path + '/' + id + '/cancel', false).pipe(
      map(response => response.data)
    )
  }

  getSettings(parameters: ISettingsSearchParams): Observable<ISettingsPaginated> {
    return this.s1HttpClient.get(this.path + this.pathSettings, parameters, true).pipe(
      map(response => { return { settings: response.data.results, total: response.data.total } })
    )
  }

  updateSettings(setting: ISetting): Observable<ISetting> {
    return this.s1HttpClient.post(this.path + this.pathSettingEdit, setting, true).pipe(
      map(response => response.data)
    )
  }

  deleteSetting(setting: ISetting): Observable<boolean> {
    return this.s1HttpClient.delete(this.path + this.pathSettingEdit + "/" + setting.idCompany + "/" + setting.idRegion + "/" + setting.idSite + "/" + setting.code, false).pipe(
        map(response => response.outcome.success)
    )
  }

  getCartsInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathCartsInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getSitesCartsStatusInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathSitesCartsStatusInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getSitesCartsRevenueInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathSitesCartsRevenueInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getDaysCartsCounterInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathDaysCartsCounterInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getDaysCartsRevenueInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathDaysCartsRevenueInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getPartialRescansInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathPartialRescansInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getTotalRescansInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathTotalRescansInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getSitesRescansInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathSitesRescansInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }
  getDaysRescansInfo(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.pathDashboard + this.pathDaysRescansInfo, parameters, false).pipe(
      map(response => response.data)
    )
  }

}
