import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mdm-input-security-network-type-select',
  templateUrl: './input-security-network-type-select.component.html',
  styleUrls: ['./input-security-network-type-select.component.scss']
})
export class InputSecurityNetworkTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() profileTypes = true;
  @Input() aeWifiConfig = false;
  @Input() bindAllObject = true;
  @Input() readonly = false;

  @Input() set subsetTypes(newValue: boolean) {
    this.types = this.aeWifiConfig ? this.getTypesAEConfig() : (this.profileTypes ? this.getTypes() : this.getTypesStaging());
    this.control.patchValue(null);
  }

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  types: Array<any> = new Array();

  constructor() { }

  ngOnInit(): void {
    if (!this.types || this.types.length === 0) {
      this.types = this.aeWifiConfig ? this.getTypesAEConfig() : (this.profileTypes ? this.getTypes() : this.getTypesStaging());
    }
  }

  private getTypes() {
    return [
      { code: 'WPA', label: 'WPA' },
      { code: 'WPA2', label: 'WPA2' },
      { code: 'WEP', label: 'WEP' }
    ];
  }

  private getTypesStaging(subsetTypes: boolean = false) {
    console.log('this.subsetTypes', subsetTypes);
    if (!subsetTypes) {
      return [
        { code: '1', label: 'WPA' },
        { code: '2', label: 'WPA2' },
        { code: '3', label: 'WPA/WPA2' },
        { code: '4', label: 'WEP' }
      ];
    } else {
      return [
        { code: '1', label: 'WPA' },
        { code: '4', label: 'WEP' }
      ];
    }
  }

  private getTypesAEConfig() {
    return [
      { code: '1', label: 'WPA-PSK' },
      // { code: '2', label: 'WPA-EAP' },
      { code: '3', label: 'WEP-PSK' },
      // { code: '4', label: 'WEP-8021X' }
    ]
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
