import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUnit } from '../models/models';
import { IProfileDistribution } from '../models/profile';
import { IProfilesSearchParams } from './profiles.service';
import { IUnitsPaginated, IUnitsSearchParams } from './units.service';
import { IConfigurationParams } from './staging.service';

export interface IApplicationSearchParams {
  packageName: string,
  idCompany?: number,
  idPolicy?: number
}

export interface IApplication {
  name: string,
  packageName: string,
  title: string,
  permissions: any[],
  managedProperties: any[]
}

export interface IApplicationPolicy {
  index?: number,
  packageName?: string,
  installType?: string,
  autoUpdateMode?: string,
  minimunVersionCode?: number,
  defaultPermissionPolicy?: string,
  managedConfiguration?: IManageConfiguration[] | IManageConfigurationMinimal[],
  permissions?: any[],
  idCompany?: number
}

export interface IPoliciesSearchParams {
  name?: string,
  idCompany: number
}

export interface IPolicy {
  id?: number,
  idProfile?: number,
  name: string,
  
  passwordQuality: string[],
  passwordScope: string[],
  requirePasswordUnlock: string[],
  maximumFailedPasswordsForWipe: number[],
  passwordHistoryLength: number[],
  passwordExpirationTimeout: string[],
  
  passwordMinimumLength: number[],
  passwordMinimumLetters: number[],
  passwordMinimumLowerCase: number[],
  passwordMinimumUpperCase: number[],
  passwordMinimumNonLetter: number[],
  passwordMinimumNumeric: number[],
  passwordMinimumSymbols: number[],
  
  defaultPermissionPolicy: string,
  type: string,
  startMinutes: number,
  endMinutes: number,
  freezePeriodStartDate: number,
  freezePeriodEndDate: number,
  kioskCustomLauncherEnabled: boolean;
  untrustedAppsPolicy: string;
  developerSettings: string;
  applications: any[],
  idAndroidEnterprise?: string,
  idCompany: number
  openNetworkConfiguration?: {
    networkConfigurations: IAEWifiConfig[]
  }
}

export interface IManageConfiguration {
  defaultValue: string,
  description: string,
  key: string,
  title: string,
  type: string,
  nestedProperties?: IManageConfiguration[],
  value?: string,
  bundles?: any
}

export interface IManageConfigurationMinimal {
  key: string,
  type: string,
  value: string,
  nestedProperties?: IManageConfigurationMinimal[]
}

export interface IWifiConfig {
  name?: string,
  hiddenWifi: boolean,
  ssid: string,
  encryption: string,
  password: string,
  wificonfig?: boolean,
  autoconnect?: boolean
}

export interface IAEWifiConfig {
  index?: number;
  guid?: string;
  name?: string;
  type?: string;
  wiFi: {
    ssid: string;
    security: string;
    passphrase: string;
    autoConnect: boolean;
    hiddenSsid: boolean;
  }
}

enum AeAPI {
  webToken = "/webToken",
  signupUrl = "/signupUrl",
  isEnterprise = "/isEnterprise",
  policy = "/policies",
  enrollmentToken = "/enrollmentToken",
  policyDistribution = "/policy-distribution/",
  application = "/application",
  applicationJson = "/policies/application-json",
  defaultApplication = "/application/smartilio-app"
}

enum AeUnitsDistributionAPI {
  listUnit = "/units",
  addUnits = "add-units",
  addAllUnit = "/add-all-available-unit",
  removeUnit = "/remove-unit",
  removeUnitList = "remove-units",
  availableUnits = "/available-units"
}



@Injectable({
  providedIn: 'root'
})
export class AeService {

  private path = "/androidmanagement";

  constructor(private s1HttpClient: S1HttpClientService) { }

  getWebToken(idCompany: string, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.get(this.path + AeAPI.webToken + "/" + idCompany, {}, ui).pipe(
      map(response => response.data.value)
    );
  }

  getEnrollmentToken(idPolicy: string, configuration: IConfigurationParams, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.post(this.path + AeAPI.enrollmentToken + "/" + idPolicy, configuration, ui).pipe(
      map(response => response.data)
    );
  }

  isEnterprise(idCompany: string, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.get(this.path + AeAPI.isEnterprise + "/" + idCompany, {}, ui).pipe(
      map(response => response.data)
    );
  }

  goToCreateEnterpriseUrl(idCompany: string, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.get(this.path + AeAPI.signupUrl + "/" + idCompany, {}, ui).pipe(
      map(response => response.data.url)
    );
  }

  getPolicies(parameters?: IPoliciesSearchParams, ui: boolean = true): Observable<IPolicy[]> {
    return this.s1HttpClient.get(this.path + AeAPI.policy + "/", parameters, ui).pipe(
      map(response => response.data.results)
    );
  }

  getPolicy(idProfile: number): Observable<IPolicy> {
    return this.s1HttpClient.get(this.path + AeAPI.policy + "/" + idProfile, {}).pipe(
      map(response => response.data)
    );
  }
  createPolicy(policy: IPolicy): Observable<IPolicy> {
    return this.s1HttpClient.post(this.path + AeAPI.policy + "/", policy).pipe(
      map(response => response.data)
    );
  }
  updatePolicy(policy: IPolicy): Observable<IPolicy> {
    return this.s1HttpClient.put(this.path + AeAPI.policy + "/" + policy.id, policy).pipe(
      map(response => response.data)
    );
  }
  deletePolicy(id: number): Observable<IPolicy> {
    return this.s1HttpClient.delete(this.path + AeAPI.policy + "/" + id).pipe(
      map(response => response.data)
    );
  }
  getApplication(parameters: IApplicationSearchParams, ui: boolean = true): Observable<IApplication> {
    const skipErrorStatus = 2;
    return this.s1HttpClient.get(this.path + AeAPI.application, parameters, ui, skipErrorStatus).pipe(
      map(response => response?.data)
    );
  }

  getApplicationJson(parameters: IApplicationSearchParams, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.get(this.path + AeAPI.applicationJson, parameters).pipe(
      map(response => response?.data.json)
    );
  }
  getApplicationDetails(parameters: IApplicationSearchParams): Observable<IApplicationPolicy> {
    return this.s1HttpClient.get(`${this.path}${AeAPI.policy}/${parameters.idPolicy}${AeAPI.application}?packageName=${parameters.packageName}`).pipe(
      map(response => response?.data)
    );
  }

  getPoliciesDistribution(parameters: IProfilesSearchParams): Observable<IProfileDistribution[]> {
    return this.s1HttpClient.get(this.path + AeAPI.policyDistribution, parameters).pipe(
      map(response => response.data.results)
    );
  }
  createPolicyDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.post(this.path + AeAPI.policyDistribution, profile).pipe(
      map(response => response.data)
    );
  }
  updatePolicyDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.put(this.path + AeAPI.policyDistribution + profile.id, profile).pipe(
      map(response => response.data)
    );
  }
  deletePolicyDistribution(id: number): Observable<any> {
    return this.s1HttpClient.delete(this.path + AeAPI.policyDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getPolicyDistributionDetail(id: number): Observable<IProfileDistribution> {
    return this.s1HttpClient.get(this.path + AeAPI.policyDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getAvailableUnitsDistribution(id: number, parameters: IUnitsSearchParams): Observable<IUnitsPaginated> {
    return this.s1HttpClient.get(this.path + AeAPI.policyDistribution + id + AeUnitsDistributionAPI.availableUnits, parameters).pipe(
      map(response => { return { units: response.data.results, total: response.data.total } })
    );
  }
  getPolicyUnitsDistribution(id: number): Observable<IUnit[]> {
    return this.s1HttpClient.get(this.path + AeAPI.policyDistribution + id + AeUnitsDistributionAPI.listUnit).pipe(
      map(response => response.data.results)
    );
  }
  setPolicyUnitsDistribution(parameters:any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + AeAPI.policyDistribution + AeUnitsDistributionAPI.addUnits, parameters).pipe(
      map(response => response.data)
    );
  }
  unsetPolicyUnitDistribution(id: number, idUnit: number): Observable<boolean> {
    return this.s1HttpClient.post(this.path + AeAPI.policyDistribution + id + AeUnitsDistributionAPI.removeUnit + '?idUnit='+idUnit, {}).pipe(
      map(response => response.data)
    );
  }
  unsetPolicyUnitListDistribution(parameters: any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + AeAPI.policyDistribution + AeUnitsDistributionAPI.removeUnitList, parameters).pipe(
      map(response => response.data)
    );
  }
  setPolicyAllUnitsDistribution(id: number, parameters: IUnitsSearchParams, forceProfile: boolean): Observable<boolean> {
    let params = this.s1HttpClient.mapParameters(parameters);
    params += "&forceProfile="+forceProfile;
    return this.s1HttpClient.post(this.path + AeAPI.policyDistribution + id + AeUnitsDistributionAPI.addAllUnit + params, {}).pipe(
      map(response => response.data)
    );
  }

  getApplicationPolicy(id: number): Observable<IApplicationPolicy> {
    return this.s1HttpClient.get(this.path + AeAPI.application + "/" + id, {}).pipe(
      map(response => response.data)
    );
  }
  createApplicationPolicy(application: IApplicationPolicy): Observable<IApplicationPolicy> {
    return this.s1HttpClient.post(this.path + AeAPI.application + "/", application).pipe(
      map(response => response.data)
    );
  }
  deleteApplicationPolicy(id: number): Observable<IApplicationPolicy> {
    return this.s1HttpClient.delete(this.path + AeAPI.application + "/" + id).pipe(
      map(response => response.data)
    );
  }
}
