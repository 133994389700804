<form [formGroup]="wificonfigForm">

  <div class="row mt-3 d-flex justify-content-center">
    <s1-input-switch *ngIf="wificonfig"
      class="col-6 col-md-3"
      [control]="wificonfigForm.controls.wificonfig"
      [label]="'profiles.network.fields.wificonfig'"
      (onToggle)="onSetWifiToggle($event)">
    </s1-input-switch>
    <s1-input-switch class="col-6 col-md-3"
      [readonly]="wificonfig && !wificonfigForm.controls.wificonfig?.value"
      [control]="wificonfigForm.controls.hiddenWifi"
      [label]="'profiles.network.fields.hidden'">
    </s1-input-switch>
    <s1-input-switch *ngIf="autoconnect"
      class="col-6 col-md-3"
      [control]="wificonfigForm.controls.autoconnect"
      [label]="'profiles.network.fields.autoconnect'">
    </s1-input-switch>
  </div>

  @if (completeConfig) {
    <div class="row text-left mt-2">
      <s1-input-text class="offset-md-3 col-12 col-md-6" [optionalTxt]="true" [control]="wificonfigForm.controls.name" [label]="'users.list.table.columns.name'" [labelGrouped]="false"></s1-input-text>
    </div>
  }

  <div class="row text-left mt-4">
    <s1-input-text [readonly]="wificonfig && !wificonfigForm.controls.wificonfig?.value" class="offset-md-3 col-12 col-md-6" [control]="wificonfigForm.controls.ssid" [label]="'profiles.network.fields.ssid'" [labelGrouped]="false"></s1-input-text>
  </div>

  <div class="row mt-4">
    <mdm-input-security-network-type-select class="offset-md-3 col-12 col-md-6"
      [readonly]="wificonfig && !wificonfigForm.controls.wificonfig?.value"
      [control]="wificonfigForm.controls.encryption"
      [bindAllObject]="true"
      [profileTypes]="false"
      [aeWifiConfig]="completeConfig"
      [subsetTypes]="true">
    </mdm-input-security-network-type-select>
  </div>

  <div class="row mt-2">
    <mdm-input-password class="offset-md-3 col-12 col-md-6"
      [readonly]="wificonfig && !wificonfigForm.controls.wificonfig?.value"
      [control]="wificonfigForm.controls.password"
      [label]="'profiles.network.fields.password'">
    </mdm-input-password>
  </div>
</form>