import { Component, Input } from '@angular/core';
import { ICart } from '@app/shared/services/carts.service';

@Component({
  selector: 'mdm-cart-status-section',
  templateUrl: './cart-status-section.component.html',
  styleUrl: './cart-status-section.component.scss'
})
export class CartStatusSectionComponent {

  @Input() cart: ICart;

  constructor() { }

}
