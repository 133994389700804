<div class="d-flex align-items-center text-center justify-content-between justify-content-md-start">
  <div class="d-flex flex-column" style="cursor: help;" [title]="'carts.cart.label.shoppingInfo' | translate">
    <mdm-cart-status
      [label]="'carts.cart.label.beginning' | translate"
      [qtyIndicator]="('carts.cart.fields.quantityItem' | translate) + ': '"
      [value]="cart?.quantity"
      [date]="cart?.tsStart"
      [noBorders]="true">
    </mdm-cart-status>
  </div>
  <ng-container *ngIf="cart?.partialVerification || cart?.totalVerification; else shoppingEnd">
    <mdm-cart-status *ngIf="cart?.partialVerification"
      [label]="'carts.cart.label.partialRescan' | translate"
      [qtyIndicator]="cart.partialVerification?.verificationResult === 'OK' ? '' : '+'"
      [value]="cart.partialVerification?.verificationResult === 'OK' ? 'OK' : cart?.partialVerification?.koQuantity"
      [date]="cart?.partialVerification?.tsVerification"
      [separator]="true"
      [ko]="cart?.partialVerification?.verificationResult === 'KO'"
      [noBorders]="cart?.partialVerification?.verificationResult === 'OK'">
    </mdm-cart-status>
    <mdm-cart-status *ngIf="cart?.totalVerification"
      [label]="'carts.cart.label.totalRescan' | translate"
      [qtyIndicator]="cart.totalVerification?.verificationResult === 'OK' ? '' : ('carts.cart.fields.quantityItem' | translate) + ': '"
      [value]="cart.totalVerification?.verificationResult === 'OK' ? 'OK' : cart?.totalVerification?.verificationQuantity"
      [date]="cart?.totalVerification?.tsVerification"
      [separator]="true"
      [ko]="cart?.totalVerification?.verificationResult === 'KO'"
      [noBorders]="cart?.totalVerification.verificationResult === 'OK'">
    </mdm-cart-status>
  </ng-container>
</div>

<ng-template #shoppingEnd>
  @if (cart?.tsEnd) {
    <mdm-cart-status
      [label]="'carts.cart.label.shoppingEnd' | translate"
      [value]="'&nbsp;'"
      [date]="cart?.tsEnd"
      [separator]="true"
      [noBorders]="true">
    </mdm-cart-status>
  }
</ng-template>