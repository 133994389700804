<div class="text-right" *ngIf="displayable">
  <div class="btn-group" dropdown>
    <button class="btn dropdown-toggle dropdown-toggle-split btn-primary" role="button" dropdownToggle>
      {{'base.order_by' | translate}}:
      <span class="ml-2">{{ 's1.sortButton.' + type | translate }}</span>
      <em class="mx-2 fa icon-arrow-{{ direction === 'asc' ? 'up' : 'down' }}" style="font-size: 10px;"></em>
    </button>
    <div *dropdownMenu class="dropdown-menu">
      <ng-container *ngFor="let element of sortingOptions.sortingElements">
        <ng-container *ngFor="let dir of buttonDirections">
          <!-- The dropdown element is displayed only if it is different from the currently selected element -->
          @if (element.key !== type || (element.key === type && dir !== direction)) {
            <a class="dropdown-item" (click)="onChange(element, dir)">
              <span>{{ 's1.sortButton.' + element.key | translate }}</span>
              <em class="mx-2 fa icon-arrow-{{ dir === 'asc' ? 'up' : 'down' }}" style="font-size: 10px;"></em>
            </a>
          }
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>