<div class="d-flex align-items-center">
  <em *ngIf="separator" class="fa fa-caret-right mx-2 mx-md-4"></em>
  <div class="status-info" [ngClass]="{'ko': ko, 'first': noBorders}">
    <small><b>{{ label }}</b></small>
    <div class="d-flex flex-column">
      <small class="qty" [ngClass]="{'bold': boldQty}"><span *ngIf="value">{{ qtyIndicator }}</span>{{ value || '0' }}</small>
      <div class="d-flex justify-content-center" [ngClass]="inlineDatetime ? 'flex-row' : 'flex-column'">
        <small>{{ date | date : 'dd/MM/yyyy' || '-' }}</small>
        <small><span *ngIf="inlineDatetime" [innerHTML]="'&nbsp;'"></span>{{ date | date : 'HH:mm' || '-' }}</small>
      </div>
    </div>
  </div>
</div>