import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdm-cart-status',
  templateUrl: './cart-status.component.html',
  styleUrl: './cart-status.component.scss'
})
export class CartStatusComponent {

  @Input() label?: string;
  @Input() qtyIndicator?: string;
  @Input() value?: string;
  @Input() date: number;
  @Input() separator?: boolean;
  @Input() ko?: boolean;
  @Input() noBorders?: boolean;
  @Input() boldQty?: boolean;
  @Input() inlineDatetime: boolean;

  constructor() { }

}
