import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem, S1ButtonType } from '@app/s1';
import { AeService, IApplication, IApplicationPolicy, IApplicationSearchParams } from '@app/shared/services/ae.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'profile-ae-form-configuration',
  templateUrl: './profile-ae-form-configuration.component.html',
  styleUrls: ['./profile-ae-form-configuration.component.scss']
})
export class ProfileAeFormConfigurationComponent implements OnInit {

  @Output() onSaved = new EventEmitter<any>();
  
  s1ButtonType = S1ButtonType;
  configurationForm: UntypedFormGroup;
  installTypeOptions = [];
  autoUpdateModeOptions = [];
  permissionPolicyOptions = [];
  
  application: IApplication;
  applicationPolicy: IApplicationPolicy;
  applicationPolicyIndex: number;

  packageNamesAlreadyUsedInPolicy = [];
  packageNameNotFound = false;
  packageName: string;
  
  applicationPermissionsFormArray: UntypedFormArray;
  applicationPermissionsOptions = [];

  automaticPackageValue = false;
  showManagedConfigurations = false;

  editMode = false;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private aeService: AeService,
    private settings: SettingsService
  ) {
    this.configurationForm = this.formBuilder.group({
      packageName: [null],
      installType: [null, Validators.required],
      autoUpdateMode: [null],
      minimunVersionCode: [null],
      defaultPermissionPolicy: [null],
      newPermissionKey: [null],
      newPermissionPolicy: [null],
      managedConfigurations: this.formBuilder.array([])
    });
    this.configurationForm.get('packageName').markAsTouched();
    this.configurationForm.get('installType').markAsTouched();
    this.installTypeOptions = [
      {"code": "INSTALL_TYPE_UNSPECIFIED", "label": "INSTALL_TYPE_UNSPECIFIED"},
      {"code:": "PREINSTALLED", "label": "PREINSTALLED"},
      {"code": "FORCE_INSTALLED", "label": "FORCE_INSTALLED"},
      {"code": "BLOCKED", "label": "BLOCKED"},
      {"code": "AVAILABLE", "label": "AVAILABLE"},
      {"code": "REQUIRED_FOR_SETUP", "label": "REQUIRED_FOR_SETUP"},
      {"code": "KIOSK", "label": "KIOSK"}
    ];
    this.autoUpdateModeOptions = [
      {"code": "AUTO_UPDATE_MODE_UNSPECIFIED", "label": "AUTO_UPDATE_MODE_UNSPECIFIED"},
      {"code": "AUTO_UPDATE_DEFAULT", "label": "AUTO_UPDATE_DEFAULT"},
      {"code": "AUTO_UPDATE_POSTPONED", "label": "AUTO_UPDATE_POSTPONED"},
      {"code": "AUTO_UPDATE_HIGH_PRIORITY", "label": "AUTO_UPDATE_HIGH_PRIORITY"}
    ];
    this.permissionPolicyOptions = [
      {"code": "PERMISSION_POLICY_UNSPECIFIED", "label": "PERMISSION_POLICY_UNSPECIFIED"},
      {"code": "PROMPT", "label": "PROMPT"},
      {"code": "GRANT", "label": "GRANT"},
      {"code": "DENY", "label": "DENY"}
    ];
  }

  ngOnInit(): void {
    this.configurationForm.controls.packageName.valueChanges.subscribe( value => {
      if(value) {
        this.packageNameNotFound = false;
      }
    });
  }

  get managedConfigurations(): UntypedFormArray {
    return <UntypedFormArray>this.configurationForm.get('managedConfigurations');
  }

  setControlError(control, error = null) {
    if(error) {
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    } else {
      control.setErrors(null);
    }
  }
  
  async inizializza(packageNamesAlreadyUsedInPolicy: string[], applicationPolicy: any, applicationPolicyIndex: number, packageName?: string, editMode: boolean = false) {
    this.packageNamesAlreadyUsedInPolicy = packageNamesAlreadyUsedInPolicy;
    this.applicationPolicy = applicationPolicy;
    this.applicationPolicyIndex = applicationPolicyIndex;
    this.application = null;
    this.packageNameNotFound = false;
    this.packageName = packageName;
    this.editMode = editMode;

    //reset
    this.configurationForm.patchValue({
      packageName: packageName ? packageName : null,
      installType: null,
      autoUpdateMode: null,
      minimunVersionCode: null,
      defaultPermissionPolicy: null,
      newPermissionKey: null,
      newPermissionPolicy: null
    });

    // If packageName is available at initialization the application info must be loaded automatically (editing ann app already available)
    if (this.packageName && (this.applicationPolicyIndex !== null && this.applicationPolicyIndex !== undefined)) {
      await this.loadApplication(packageName, applicationPolicy);
      this.automaticPackageValue = true;

      /** The options already configured by default are then removed from the list, since not editable */
      this.applicationPermissionsOptions = this.applicationPermissionsOptions.filter(t => t.code !== this.applicationPolicy?.permissions?.find(p => !!p.policy)?.permissionId);
    } else {
      await this.setApplicationPolicyForm(applicationPolicy?.packageName);
    }
  }

  async loadApplication(packageName: any, application?: any) {
    this.packageNameNotFound = false;
    await this.setApplicationPolicyForm(packageName, application);
  }

  async setApplicationPolicyForm(packageName: string, application?: any) {
    this.applicationPermissionsFormArray =  this.formBuilder.array([]);

    if(packageName && !this.packageNameNotFound) {
      //nuova configurazione, applicationPolicy null, filtro su packageNameAlreadyUsedInPolicy
      //modifica configurazione, packageName non modificabile
      //get struttura da packageName
      let parameters: IApplicationSearchParams = {
        packageName: packageName,
        idCompany: this.settings.getCompany().code,
      }

      /** On edit, when an application is already available, that app is passed to the configuration, else the application is fetched by API */
      this.application = application ? application : await firstValueFrom(this.aeService.getApplication(parameters));

      if(!this.application) {
        this.setControlError(this.configurationForm.controls.packageName, true);
        this.packageNameNotFound = true;
      } else {
        this.configureApplication(packageName, this.application);
      }
    }
  }

  cloneArrayBundle(idx: number) {
    let bundle_array = {...this.application.managedProperties[idx].nestedProperties.at(-1), clone: true};
    this.application.managedProperties[idx].nestedProperties.push(bundle_array);
  }

  addPermission() {
    if(!this.applicationPolicy?.permissions) {
      this.applicationPolicy = {
        permissions: []
      }
    }

    /** The permission option just set is removed from the options list (the permission was already configured and cannot be edited) */
    this.applicationPermissionsOptions = this.applicationPermissionsOptions.filter(p => p.code !== this.configurationForm?.controls.newPermissionKey.value);

    this.applicationPolicy.permissions.push({'permissionId': this.configurationForm?.controls.newPermissionKey.value});
    this.applicationPermissionsFormArray.push(this.formBuilder.control(this.configurationForm?.controls.newPermissionPolicy.value));
    this.configurationForm.patchValue({
      newPermissionKey: null,
      newPermissionPolicy: null
    });
  }

  save(): void {
    let permissions = [];
    for (let i = 0; i < this.applicationPermissionsFormArray?.value?.length; i++) {
      if(this.applicationPermissionsFormArray?.value[i]) {
        permissions.push({
          "permissionId": this.applicationPolicy.permissions[i].permissionId,
          "policy": this.applicationPermissionsFormArray?.value[i]
        });
      }
    }

    const application: IApplicationPolicy = {
      index: this.applicationPolicyIndex,
      packageName: this.application.packageName,
      installType: this.configurationForm?.controls.installType.value,
      autoUpdateMode: this.configurationForm?.controls.autoUpdateMode.value,
      minimunVersionCode: this.configurationForm?.controls.minimunVersionCode.value,
      defaultPermissionPolicy: this.configurationForm?.controls.defaultPermissionPolicy.value,
      managedConfiguration: this.application?.managedProperties,
      permissions: permissions,
      idCompany: this.settings.getCompany().code,
      ...(this.editMode ? { modified: true, added: false } : { added: true, modified: false })
    };
    this.onSaved.emit(application);
  }

  onShowManagedConfigurations(): void {
    this.showManagedConfigurations = !this.showManagedConfigurations;
  }

  onManagedConfigurationRemove(configIdx: number, managedConfigIdx: number): void {
    this.application.managedProperties[managedConfigIdx].nestedProperties.splice(configIdx, 1);
  }

  onManagedConfigurationExpand(propertiesNode: any): void {
    propertiesNode.open = !propertiesNode.open ? true : false;
  }

  onManagedConfigurationChange(propertiesNode: any, configValue: IS1InputSelectItem | string | number): void {
    propertiesNode.value = configValue !== null && configValue !== undefined
      ? ((configValue['code'] !== null && configValue['code'] !== undefined) ? configValue['code'] : configValue)
      : propertiesNode.defaultValue;
  }

  // --------- PRIVATE METHODS --------- //

  private configureApplication(packageName: string, application: any): void {
    this.application = application;

    this.application.packageName = packageName;
    
    /**
     * If the application has managedConfiguration, meaning we're editing it (the edit service returns this structure instead of managedProperties)
     * then the latter has to be valorized with the current values
     */
    if (this.application['managedConfiguration']) {
      this.application.managedProperties = this.application['managedConfiguration'];
    }
    
    this.packageNameNotFound = false;
    
    this.configurePermissions();

    this.configurationForm.patchValue({
      packageName: this.application?.packageName,
      installType: this.applicationPolicy?.installType,
      autoUpdateMode: this.applicationPolicy?.autoUpdateMode,
      minimunVersionCode: this.applicationPolicy?.minimunVersionCode,
      defaultPermissionPolicy: this.applicationPolicy?.defaultPermissionPolicy,
      newPermissionKey: null,
      newPermissionPolicy: null
    });
  }

  private configurePermissions(): void {
    //init form permissions: dal json mi prendo il nodo permissions e lo carico nella select.
    if (!this.applicationPermissionsOptions.length) {
      this.applicationPermissionsOptions = this.application.permissions?.map(applicationPermission => {
        return { code: applicationPermission?.permissionId, label: applicationPermission?.permissionId?.split('.').pop() };
      });
    }
    
    //carico quelli precedentemente aggiunti nell'array
    for (let i = 0; i < this.applicationPolicy?.permissions?.length; i++) {
      this.applicationPermissionsFormArray.push(this.formBuilder.control(this.applicationPolicy?.permissions[i].policy));
    }
  }

}