<div class="input-group mb-2" *ngIf="grouped">
  <div class="input-group-prepend" *ngIf="label" [ngClass]="styleClass">
    <span class="input-group-text">{{ label | translate }}<span *ngIf="required" class="text-primary">*</span><span *ngIf="optionalTxt" class="ml-1">({{ 'base.optional' | translate | lowercase }})</span></span>
  </div>
  <ng-container *ngTemplateOutlet="grouped? content : null"></ng-container>
  <ng-content></ng-content>
</div>

<ng-container *ngIf="!grouped">
  <label *ngIf="label">{{ label | translate }}<span *ngIf="required" class="text-primary">*</span><span *ngIf="optionalTxt" class="ml-1">({{ 'base.optional' | translate | lowercase }})</span></label>
  <ng-container *ngTemplateOutlet="!grouped? content : null"></ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>