import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Question = "question"
}

export interface SelectItem {
  code: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class S1UIService {

  private visibleLoader: boolean = false;

  constructor(private translate: TranslateService, private toasterService: ToastrService) { }

  showBlockingSpinner() {
    if (!swal.isVisible()) {
      swal.fire({
        title: this.translate.instant('s1.swal.loading'),
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowEscapeKey: false,
        html: `
        <div class="d-flex align-items-center justify-content-center ">
          <svg viewBox="0 0 700 200">
            <switch>
              <g transform="translate(185, 0)">
                <g id="main">
                  <ellipse ry="32.1" rx="55.5" cy="129.4" cx="155.7" style="fill:#0371A1;"></ellipse>
                  <g class="main_shadow" style="opacity:0.85;"></g>
                  <path d="M155.7,156.3c-27.3,0-50.4-12.3-50.4-26.9s23.1-26.9,50.4-26.9s50.4,12.3,50.4,26.9S183.1,156.3,155.7,156.3z" class="circle" style="fill:none;stroke:#69B9F0;stroke-width:1.1749;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"></path>
                </g>
                <g id="smartilio">
                  <rect height="21" width="6" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;" y="31.6" x="153.7"></rect>
                  <path d="M156.7,23.8c-1,0-1.9,0.5-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.7l0,0c0,1,0.4,2,1.1,2.7     c0.7,0.7,1.6,1.1,2.7,1.1l0,0c1,0,2-0.4,2.6-1.1s1.1-1.6,1.1-2.7l0,0c0-1-0.4-2-1.1-2.6C158.7,24.2,157.7,23.8,156.7,23.8     L156.7,23.8z M150.7,21.6c3.3-3.3,8.7-3.3,12,0c1.5,1.5,2.5,3.6,2.5,6l0,0l0,0c0,2.3-0.9,4.4-2.5,6c-1.5,1.5-3.6,2.5-6,2.5l0,0     c-2.3,0-4.4-1-6-2.5c-1.5-1.5-2.5-3.6-2.5-6l0,0C148.2,25.2,149.2,23.1,150.7,21.6L150.7,21.6z" style="fill:#0371A1;"></path>
                  <circle r="41" cy="88.2" cx="155.7" style="fill:#FFFFFF;"></circle>
                  <path d="M195.7,79.8c0,2.7-15,13.4-37.6,13.4S119,87.1,115.8,80c0,0,4-32.8,42.9-33     C191.8,49,195.7,79.8,195.7,79.8z" style="fill:#0371A1;"></path>
                  <circle r="9.1" cy="88.1" cx="129" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"></circle>
                  <path d="M124.4,82.6c-3.1,2.6-3.4,7.2-0.8,10.3     c2.6,3.1,7.1,3.4,10.2,0.8c3-2.6,3.4-7.2,0.8-10.3C132,80.3,127.4,80,124.4,82.6L124.4,82.6z M131.2,82.9     c-0.6-0.2-1.2-0.4-1.8-0.4c-1.4-0.1-3,0.3-4.2,1.4c-1.2,1-1.9,2.5-2,3.9c-0.1,0.8,0,1.6,0.3,2.3l1.8-1.1c-0.1-0.3-0.1-0.7-0.1-1     c0.1-0.9,0.5-1.9,1.3-2.5c0.8-0.7,1.7-1,2.7-0.9c0.4,0,0.8,0.2,1.2,0.3L131.2,82.9z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <circle r="9.1" cy="92.8" cx="159.7" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"></circle>
                  <path d="M155,87.3c-3.1,2.6-3.4,7.2-0.8,10.3     c2.6,3.1,7.1,3.4,10.2,0.8c3-2.6,3.4-7.2,0.8-10.3C162.6,85.1,158,84.8,155,87.3L155,87.3z M161.8,87.7c-0.6-0.2-1.2-0.4-1.8-0.4     c-1.4-0.1-3,0.3-4.2,1.4c-1.2,1-1.9,2.5-2,3.9c-0.1,0.8,0,1.6,0.3,2.3l1.8-1.1c-0.1-0.3-0.1-0.7-0.1-1c0.1-0.9,0.5-1.9,1.3-2.5     c0.8-0.7,1.7-1,2.7-0.9c0.4,0,0.8,0.2,1.2,0.3L161.8,87.7z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <path d="M185.4,108.3c-0.1,0-0.2,0-0.4-0.1c-0.2-0.2-0.3-0.4-0.2-0.7     c0-0.1,0-0.2,0-0.2c0.4-1.8,0.7-3.8,0.8-5.8c0-0.1,0-0.2,0.1-0.2c0.8-1.6,1.2-3.4,1.1-5.1c-0.1-1.9-0.7-3.8-1.8-5.4     c-0.1-0.1-0.1-0.2-0.1-0.2c-0.4-2-0.9-4-1.6-5.9c-0.1-0.2,0-0.5,0.2-0.7c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.3,0.1     c0.9,0.5,1.7,1.1,2.5,1.9c2.9,2.7,4.6,6.3,4.8,10.2c0.2,3.9-1.2,7.7-3.9,10.6c-0.5,0.6-1.1,1.2-1.8,1.7     C185.7,108.3,185.6,108.3,185.4,108.3z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <path d="M183.9,84.4c0.9,0.5,1.7,1.1,2.4,1.8c2.8,2.5,4.4,6,4.6,9.8c0.2,3.7-1.2,7.4-3.7,10.2     c-0.5,0.6-1.1,1.1-1.7,1.6c0-0.1,0-0.2,0-0.2c0.4-1.9,0.7-3.9,0.8-5.9c0.9-1.7,1.3-3.5,1.2-5.4c-0.1-2.1-0.8-4-1.9-5.7     C185.1,88.4,184.6,86.4,183.9,84.4 M183.9,83.2c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.3-0.5,0.9-0.4,1.3c0.7,1.9,1.2,3.8,1.6,5.8     c0,0.2,0.1,0.3,0.2,0.5c1,1.5,1.6,3.2,1.7,5.1c0.1,1.7-0.3,3.3-1.1,4.8c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,2-0.4,3.9-0.8,5.8     c0,0.1,0,0.1,0,0.2c-0.1,0.5,0.1,1,0.5,1.3c0.2,0.2,0.5,0.2,0.7,0.2c0.3,0,0.5-0.1,0.7-0.2c0.7-0.5,1.3-1.1,1.9-1.8     c2.8-3,4.2-6.9,4-11s-1.9-7.9-4.9-10.6c-0.8-0.8-1.7-1.4-2.6-1.9C184.3,83.2,184.1,83.2,183.9,83.2L183.9,83.2z" style="fill:#FFFFFF;"></path>
                  <path d="M130.7,108.1c2.9,10,17.7,12.3,27,7.6c1.3-0.7,0.1-2.6-1.4-2     C147.5,117.3,136.4,115.4,130.7,108.1L130.7,108.1z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <path d="M135.8,106.6c-2-0.6-4.2,0.1-5.6,1.8c-1.4,1.7-1.4,4-0.4,5.8     c-0.3-1.6,0.2-3.5,1.4-5.1C132.4,107.6,134.1,106.7,135.8,106.6L135.8,106.6z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                </g>
              </g>
            </switch>
          </svg>
        </div>`,
      });
    }
  }
  
  updateFooter(text) {
    if(swal.isVisible()) {
      swal.update({
        html: '<strong class="text-primary">'+text+'</strong>'
      });
    }
  }
  
  // Spinner
  showSpinner(customText?: string) {
    if (!this.visibleLoader) {
      this.visibleLoader = true;
      swal.fire({
        title: customText ? customText : this.translate.instant('s1.swal.loading'),
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowEscapeKey: false,
        html: `
        <div class="d-flex align-items-center justify-content-center ">
          <svg viewBox="0 0 700 200">
            <switch>
              <g transform="translate(185, 0)">
                <g id="main">
                  <ellipse ry="32.1" rx="55.5" cy="129.4" cx="155.7" style="fill:#0371A1;"></ellipse>
                  <g class="main_shadow" style="opacity:0.85;"></g>
                  <path d="M155.7,156.3c-27.3,0-50.4-12.3-50.4-26.9s23.1-26.9,50.4-26.9s50.4,12.3,50.4,26.9S183.1,156.3,155.7,156.3z" class="circle" style="fill:none;stroke:#69B9F0;stroke-width:1.1749;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"></path>
                </g>
                <g id="smartilio">
                  <rect height="21" width="6" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;" y="31.6" x="153.7"></rect>
                  <path d="M156.7,23.8c-1,0-1.9,0.5-2.6,1.1c-0.7,0.7-1.1,1.6-1.1,2.7l0,0c0,1,0.4,2,1.1,2.7     c0.7,0.7,1.6,1.1,2.7,1.1l0,0c1,0,2-0.4,2.6-1.1s1.1-1.6,1.1-2.7l0,0c0-1-0.4-2-1.1-2.6C158.7,24.2,157.7,23.8,156.7,23.8     L156.7,23.8z M150.7,21.6c3.3-3.3,8.7-3.3,12,0c1.5,1.5,2.5,3.6,2.5,6l0,0l0,0c0,2.3-0.9,4.4-2.5,6c-1.5,1.5-3.6,2.5-6,2.5l0,0     c-2.3,0-4.4-1-6-2.5c-1.5-1.5-2.5-3.6-2.5-6l0,0C148.2,25.2,149.2,23.1,150.7,21.6L150.7,21.6z" style="fill:#0371A1;"></path>
                  <circle r="41" cy="88.2" cx="155.7" style="fill:#FFFFFF;"></circle>
                  <path d="M195.7,79.8c0,2.7-15,13.4-37.6,13.4S119,87.1,115.8,80c0,0,4-32.8,42.9-33     C191.8,49,195.7,79.8,195.7,79.8z" style="fill:#0371A1;"></path>
                  <circle r="9.1" cy="88.1" cx="129" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"></circle>
                  <path d="M124.4,82.6c-3.1,2.6-3.4,7.2-0.8,10.3     c2.6,3.1,7.1,3.4,10.2,0.8c3-2.6,3.4-7.2,0.8-10.3C132,80.3,127.4,80,124.4,82.6L124.4,82.6z M131.2,82.9     c-0.6-0.2-1.2-0.4-1.8-0.4c-1.4-0.1-3,0.3-4.2,1.4c-1.2,1-1.9,2.5-2,3.9c-0.1,0.8,0,1.6,0.3,2.3l1.8-1.1c-0.1-0.3-0.1-0.7-0.1-1     c0.1-0.9,0.5-1.9,1.3-2.5c0.8-0.7,1.7-1,2.7-0.9c0.4,0,0.8,0.2,1.2,0.3L131.2,82.9z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <circle r="9.1" cy="92.8" cx="159.7" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"></circle>
                  <path d="M155,87.3c-3.1,2.6-3.4,7.2-0.8,10.3     c2.6,3.1,7.1,3.4,10.2,0.8c3-2.6,3.4-7.2,0.8-10.3C162.6,85.1,158,84.8,155,87.3L155,87.3z M161.8,87.7c-0.6-0.2-1.2-0.4-1.8-0.4     c-1.4-0.1-3,0.3-4.2,1.4c-1.2,1-1.9,2.5-2,3.9c-0.1,0.8,0,1.6,0.3,2.3l1.8-1.1c-0.1-0.3-0.1-0.7-0.1-1c0.1-0.9,0.5-1.9,1.3-2.5     c0.8-0.7,1.7-1,2.7-0.9c0.4,0,0.8,0.2,1.2,0.3L161.8,87.7z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <path d="M185.4,108.3c-0.1,0-0.2,0-0.4-0.1c-0.2-0.2-0.3-0.4-0.2-0.7     c0-0.1,0-0.2,0-0.2c0.4-1.8,0.7-3.8,0.8-5.8c0-0.1,0-0.2,0.1-0.2c0.8-1.6,1.2-3.4,1.1-5.1c-0.1-1.9-0.7-3.8-1.8-5.4     c-0.1-0.1-0.1-0.2-0.1-0.2c-0.4-2-0.9-4-1.6-5.9c-0.1-0.2,0-0.5,0.2-0.7c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.3,0.1     c0.9,0.5,1.7,1.1,2.5,1.9c2.9,2.7,4.6,6.3,4.8,10.2c0.2,3.9-1.2,7.7-3.9,10.6c-0.5,0.6-1.1,1.2-1.8,1.7     C185.7,108.3,185.6,108.3,185.4,108.3z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <path d="M183.9,84.4c0.9,0.5,1.7,1.1,2.4,1.8c2.8,2.5,4.4,6,4.6,9.8c0.2,3.7-1.2,7.4-3.7,10.2     c-0.5,0.6-1.1,1.1-1.7,1.6c0-0.1,0-0.2,0-0.2c0.4-1.9,0.7-3.9,0.8-5.9c0.9-1.7,1.3-3.5,1.2-5.4c-0.1-2.1-0.8-4-1.9-5.7     C185.1,88.4,184.6,86.4,183.9,84.4 M183.9,83.2c-0.3,0-0.6,0.1-0.8,0.3c-0.4,0.3-0.5,0.9-0.4,1.3c0.7,1.9,1.2,3.8,1.6,5.8     c0,0.2,0.1,0.3,0.2,0.5c1,1.5,1.6,3.2,1.7,5.1c0.1,1.7-0.3,3.3-1.1,4.8c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,2-0.4,3.9-0.8,5.8     c0,0.1,0,0.1,0,0.2c-0.1,0.5,0.1,1,0.5,1.3c0.2,0.2,0.5,0.2,0.7,0.2c0.3,0,0.5-0.1,0.7-0.2c0.7-0.5,1.3-1.1,1.9-1.8     c2.8-3,4.2-6.9,4-11s-1.9-7.9-4.9-10.6c-0.8-0.8-1.7-1.4-2.6-1.9C184.3,83.2,184.1,83.2,183.9,83.2L183.9,83.2z" style="fill:#FFFFFF;"></path>
                  <path d="M130.7,108.1c2.9,10,17.7,12.3,27,7.6c1.3-0.7,0.1-2.6-1.4-2     C147.5,117.3,136.4,115.4,130.7,108.1L130.7,108.1z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                  <path d="M135.8,106.6c-2-0.6-4.2,0.1-5.6,1.8c-1.4,1.7-1.4,4-0.4,5.8     c-0.3-1.6,0.2-3.5,1.4-5.1C132.4,107.6,134.1,106.7,135.8,106.6L135.8,106.6z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#0371A1;"></path>
                </g>
              </g>
            </switch>
          </svg>
        </div>`,
      }).then();
    }
  }

  closeSpinner() {
    this.visibleLoader = false;
    swal.close();
  }

  // Popup
  showPopup(type: S1PopUpType, title?: string, text?: string) {
    swal.fire(this.translate.instant(title), this.translate.instant(text), type);
  }

  showErrorPopup(errorMessage: string) {
    this.showPopup(S1PopUpType.Error, this.translate.instant('s1.swal.error'), this.translate.instant(errorMessage))
  }

  showPopupNoPermission() {
    this.showPopup(S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission')
  }

  showHTTPErrorPopup(error: HttpErrorResponse) {
    let errorTitle = 's1.swal.error';
    let errorMessage = 'genericError';
    
    if (error.statusText) {
      errorMessage = error.statusText;
    }

    if (error.status) {
      errorTitle = '' + error.status;
      switch (error.status) {
        case 13: // duplicated
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.code_already_used');
          break;
        case 23: // max file size
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_not_coherent');
          break;
        case 25: // ipaddress not coherent
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_not_coherent');
          break;
        case 26: // ipaddress to > from
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_from_to_error');
          break;
        case 27: // ipaddress or subnet mask format not valid
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_wrong_format');
          break;
        case 30:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_fields_row_file_error')}`;
          break;
        case 31:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_unit_code_file_error')}`;
          break;
        case 32:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_timezone_file_error')}`;
          break;
        case 33:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_timezone_file_error')}`;
          break;
        case 37:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_from_date_file_error')}`;
          break;
        case 38:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_from_date_file_error')}`;
          break;
        case 39:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_from_time_file_error')}`;
          break;
        case 40:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.generic_from_datetime_file_error')}`;
          break;
        case 41:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_end_date_file_error')}`;
          break;
        case 42:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_end_time_file_error')}`;
          break;
        case 43:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.generic_end_datetime_file_error')}`;
          break;
        case 44:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.endtime_higher_fromtime_file_error')}`;
          break;
        case 45:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.unit_code_existing_file_error')}`;
          break;
        case 46:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.unit_code_db_existing_file_error')}`;
          break;
        case 47:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.exceeding_file_error')}`;
          break;
        case 48:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.exceeding_row_file_error')}`;
          break;
        case 49:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.exceeding_unit_code_file_error')}`;
          break;
        case 53: // duplicated
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.shop_setting_already_present');
          break;
        case 55: // too many results
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.too_many_results');
          break;
        case 57:
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.unit_placeholder_unavailable');
          break;
        case 401: // Unauthorized
          errorMessage = this.translate.instant('APIErrors.' + error.status);
          break;
        case 403: // Forbidden
          swal.close();
          return;
      }
    }

    this.showPopup(S1PopUpType.Error, errorTitle, errorMessage);
  }

  showDialogPopup(title: string, type?: SweetAlertIcon): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: type ? type : S1PopUpType.Question,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn-danger'
      }
    })

  }

  showDialogWithInputTextPopup(inputMessage: string, params?: Object): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      icon: S1PopUpType.Question,
      html: `<label>`+ this.translate.instant(inputMessage, params) +`</label><input type="text" id="content" class="swal2-input" maxlength="255">`,
      preConfirm: () => {
        const content: any = swal.getPopup().querySelector('#content');
        return { 'text': content.value };
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn-danger'
      }
      
    })

  }

  _showDialogWithInputTextAndCheckPopup(inputMessage: string, flagMessage: string, failureMessage: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      icon: S1PopUpType.Question,
      html: `<label class="mr-3">
                <input type="checkbox" id="flagFailure" class="mr-2 mb-4">`+ this.translate.instant(failureMessage)
          +`</label>
            <label>
                <input type="checkbox" id="flag" class="mr-2 mb-4">`+ this.translate.instant(flagMessage)
          +`</label>
             <label>`+ this.translate.instant(inputMessage) +`</label><input type="text" id="content" class="swal2-input" maxlength="255">`,
      preConfirm: () => {
        const content: any = swal.getPopup().querySelector('#content');
        const flag: any = swal.getPopup().querySelector('#flag');
        const flagFailure: any = swal.getPopup().querySelector('#flagFailure');
        return { 'text': content.value, 'flag': flag.checked, 'flagFailure': flagFailure.checked };
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn-danger'
      }

    })

  }

  showDialogWithCheckPopup(title: string, options: any = {}): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      inputValue: 'no',
      input: 'radio',
      inputOptions: options,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!'
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn-danger'
      }
    })

  }

  showDialogWithCheckBoxPopup(title: string, option: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      inputValue: null,
      input: 'checkbox',
      inputPlaceholder: option,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn-danger'
      }
    })

  }

  getProcessedClassesFromObject(object : any, classIfNull : string)  : [] {  
    var objectClasses : [] = [];

    Object.keys(object).forEach(key => {
      const ngClass = this.setObjectClass(object[key], classIfNull);
      objectClasses[key] = ngClass;
    });
  
    return objectClasses;    
  }


  setObjectClass(condition : any, defaultClass : string) {

    if(condition != null && condition != false){
      return '';
    } else {
      return defaultClass
    }

  }

  // TOAST
  showSuccessToast(text: string) {
    this.toasterService.success(this.translate.instant(text), this.translate.instant("s1.toast.success"));
  }

  showErrorToast(text: string) {
    this.toasterService.error(this.translate.instant(text), this.translate.instant("s1.toast.error"));
  }

}
