import { Component } from '@angular/core';
import { TranslatorService } from '@app/core/translator/translator.service';

@Component({
  selector: 's1-language',
  templateUrl: './s1-language.component.html',
  styleUrls: ['./s1-language.component.scss']
})
export class S1Language {

  constructor(public translator: TranslatorService ) { }

  getLangs(): { code: string, text: string }[] {
    return this.translator.getAvailableLanguages();
  }

  setLang(value: string): void {
    this.translator.useLanguage(value);
  }

}