import { Component, Input, OnInit } from '@angular/core';

export enum S1ButtonSortDirections {
  ASC = 'asc',
  DESC = 'desc'
}

export interface IS1Sorting {
  key: string;
  direction?: string;
}

export interface IS1CustomSorting {
  key: string;
  controls?: string[];
}

export interface IS1SortingOptions {
  default: IS1Sorting;
  sortingElements: (IS1Sorting | IS1CustomSorting)[];
}

@Component({
  selector: 's1-button-sort',
  templateUrl: './s1-button-sort.component.html',
  styleUrl: './s1-button-sort.component.scss'
})
export class S1ButtonSort implements OnInit {

  @Input() list: any[];
  /**
   * Sorting button options, where the default policy and sorting elements are defined.
   * 
   * Elements are always defined by strings, taken by themselves when the sorting has to 
   * consider the object property itself, defined by its name, whereas apply checks on
   * multiple object properties when defined as string arrays.
   */
  @Input() sortingOptions: IS1SortingOptions;

  type: string;
  direction: string;
  displayable: boolean;

  buttonDirections = Object.values(S1ButtonSortDirections);

  constructor() { }

  ngOnInit(): void {
    if (this.sortingOptions) {
      this.displayable = true;
      this.configureDefaultSorting(); 
    }
  }

  onChange(elements: IS1CustomSorting, direction: string): void {
    this.type = elements.key;
    this.direction = direction;

    /** When the element has custom controls (so when elements and check are not null), the element must be passed to the sorting algorithm */
    this.sortList(elements.controls ? elements : null);
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that sets the default sorting option based on what was received by the container */
  private configureDefaultSorting(): void {
    this.type = this.sortingOptions?.default?.key;
    this.direction = this.sortingOptions?.default?.direction;
  }

  /** Method that sorts the list of options by the type and direction, and applying eventual custom controls */
  private sortList(customControl?: IS1CustomSorting): void {
    this.list.sort((a, b) => {
      /** First and last elements are defined by sort direction */
      const firstEl = this.direction === S1ButtonSortDirections.ASC ? a : b;
      const lastEl = this.direction === S1ButtonSortDirections.ASC ? b : a;

      /** When a custom control is given to the sorting component then the list must be sorted according to it */
      if (customControl) {
        for (const c of customControl.controls) {
          const comparison = this.manageSorting(firstEl, lastEl, c);
          if (comparison !== 0) return comparison;
        }
        return 0;
      } else {
        return this.manageSorting(firstEl, lastEl, this.type);
      }
    });
  }

  /** Check is different based on the firstEl type, string in fact requires toLowerCase and must be checked by localeCompare */
  private manageSorting(firstEl: string, lastEl: string, controlType: string): number {
    return typeof firstEl[controlType] === 'string'
      ? firstEl[controlType]?.toLowerCase().localeCompare(lastEl[controlType]?.toLowerCase())
      : firstEl[controlType] - lastEl[controlType];
  }

}
