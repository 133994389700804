<div class="d-flex text-center item-section justify-content-between justify-content-md-around">
  <mdm-cart-status
    [label]="'carts.list.table.columns.quantity' | translate"
    [value]="cartItem?.quantity"
    [date]="cartItem?.tsScan"
    [boldQty]="true"
    [inlineDatetime]="true"
  ></mdm-cart-status>
  <mdm-cart-status [ngClass]="{ 'hidden': !cartItem?.partialVerificationQuantity }"
    [label]="'carts.cart.label.partialRescan' | translate"
    [value]="cartItem?.partialVerificationResult === 'OK' ? 'OK' : cartItem?.partialVerificationQuantity"
    [ko]="cartItem?.partialVerificationResult === 'KO'"
    [boldQty]="true">
  </mdm-cart-status>
  <mdm-cart-status [ngClass]="{ 'hidden': !cartItem?.totalVerificationQuantity }"
    [label]="'carts.cart.label.totalRescan' | translate"
    [value]="cartItem?.totalVerificationResult === 'OK' ? 'OK' : cartItem?.totalVerificationQuantity"
    [ko]="cartItem?.totalVerificationResult === 'KO'"
    [boldQty]="true">
  </mdm-cart-status>
</div>