import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { S1ButtonType } from '@app/s1';
import { IAEWifiConfig, IWifiConfig } from '@app/shared/services/ae.service';

@Component({
  selector: 'profile-ae-form-wificonfig',
  templateUrl: './profile-ae-form-wificonfig.component.html',
  styleUrl: './profile-ae-form-wificonfig.component.scss'
})
export class ProfileAeFormWificonfigComponent {

  @Output() onSaved = new EventEmitter<IAEWifiConfig>();

  s1ButtonType = S1ButtonType;
  profileWifiConfig: UntypedFormGroup;
  wifiConfigIndex: number;
  wifiConfigGuid: string;
  wifiValues: IWifiConfig;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.profileWifiConfig = this.formBuilder.group({});
  }

  inizializza(wifiConfig: IAEWifiConfig): void {
    this.wifiConfigIndex = wifiConfig.index;
    this.wifiConfigGuid = wifiConfig.guid;
    this.wifiValues = {
      name: wifiConfig.name,
      ssid: wifiConfig.wiFi?.ssid,
      encryption: wifiConfig.wiFi?.security,
      password: wifiConfig.wiFi?.passphrase,
      autoconnect: wifiConfig.wiFi.autoConnect,
      hiddenWifi: wifiConfig.wiFi.hiddenSsid
    }
  }

  onWifiChange(form: UntypedFormGroup) {
    this.profileWifiConfig = form;
  }

  save(): void {
    const wifiConfig: IAEWifiConfig = {
      ...(this.wifiConfigIndex >= 0) && { index: this.wifiConfigIndex },
      ...(this.wifiConfigGuid) && { guid: this.wifiConfigGuid },
      name: this.profileWifiConfig.controls?.name?.value ? this.profileWifiConfig.controls?.name?.value : null,
      type: 'WiFi',
      wiFi: {
        ssid: this.profileWifiConfig.controls?.ssid?.value,
        security: this.profileWifiConfig.controls?.encryption?.value.label,
        passphrase: this.profileWifiConfig.controls?.password?.value,
        autoConnect: this.profileWifiConfig.controls?.autoconnect?.value,
        hiddenSsid: this.profileWifiConfig.controls?.hiddenWifi?.value
      }
    }

    this.onSaved.emit(wifiConfig);
  }

}
