import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IWifiConfig } from '@app/shared/services/ae.service';

@Component({
  selector: 'mdm-wifi-config',
  templateUrl: './wifi-config.component.html',
  styleUrl: './wifi-config.component.scss'
})
export class WifiConfigComponent implements OnInit {

  // Inputs
  @Input() wificonfig?: boolean;
  @Input() autoconnect?: boolean;
  @Input() completeConfig?: boolean;
  @Input() set existingFormValue(config: IWifiConfig) {
    this.wificonfigForm?.patchValue({
      hiddenWifi: config.hiddenWifi,
      ssid: config.ssid,
      encryption: { code: null, label: config.encryption },
      password: config.password,
      ...(this.wificonfig) && { wificonfig: config.wificonfig },
      ...(this.autoconnect) && { autoconnect: config.autoconnect },
      ...(this.completeConfig) && { name: config.name }
    })
  };
  
  //Outputs
  @Output() wificonfigChange = new EventEmitter<UntypedFormGroup>();

  wificonfigForm: UntypedFormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.wificonfigForm = this.formBuilder.group({
      hiddenWifi: false,
      ssid: [null, Validators.required],
      encryption: [null, Validators.required],
      password: [null, Validators.required],
      ...(this.wificonfig) && { wificonfig: true },
      ...(this.autoconnect) && { autoconnect: false },
      ...(this.completeConfig) && { name: null }
    });

    /** On a complete wifi configuration all the fields but name, must be required, behaving exactly as if wifi config was set to true */
    if (this.completeConfig) {
      this.onSetWifiToggle(true);
    } else {
      this.wificonfigChange.emit(this.wificonfigForm);
    }
  }

  onSetWifiToggle(toggleValue: boolean) {
    if (!toggleValue) {
      this.wificonfigForm?.controls.hiddenWifi?.setValue(false);
      this.wificonfigForm?.controls.autoconnect?.setValue(false);
      
      this.wificonfigForm?.controls.ssid?.clearValidators();
      this.wificonfigForm?.controls.ssid?.updateValueAndValidity();
      this.wificonfigForm?.controls.ssid?.setValue(null);
      
      this.wificonfigForm?.controls.encryption?.clearValidators();
      this.wificonfigForm?.controls.encryption?.updateValueAndValidity();
      this.wificonfigForm?.controls.encryption?.setValue(null);
      
      this.wificonfigForm?.controls.password?.clearValidators();
      this.wificonfigForm?.controls.password?.updateValueAndValidity();
      this.wificonfigForm?.controls.password?.setValue(null);
    } else {
      this.wificonfigForm?.controls.ssid?.setValidators(Validators.required);
      this.wificonfigForm?.controls.ssid?.updateValueAndValidity();
      
      this.wificonfigForm?.controls.encryption?.setValidators(Validators.required);
      this.wificonfigForm?.controls.encryption?.updateValueAndValidity();
      
      this.wificonfigForm?.controls.password?.setValidators(Validators.required);
      this.wificonfigForm?.controls.password?.updateValueAndValidity();
    }
    
    this.wificonfigChange.emit(this.wificonfigForm);
  }

}
