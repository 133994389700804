import { Component, Input } from '@angular/core';
import { ICartItem } from '@app/shared/services/carts.service';

@Component({
  selector: 'mdm-cart-item-status-section',
  templateUrl: './cart-item-status-section.component.html',
  styleUrl: './cart-item-status-section.component.scss'
})
export class CartItemStatusSectionComponent {

  @Input() cartItem: ICartItem;

  constructor() { }

}
