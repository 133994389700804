<form [formGroup]="configurationForm">
  <div class="row text-left">
    <s1-input-text class="col-10 mb-2" [readonly]="applicationPolicy?.packageName || automaticPackageValue" [control]="configurationForm.controls.packageName" [label]="'aeapplications.list.table.columns.application'" [labelGrouped]="false"></s1-input-text>
    <s1-button class="mt-center" [type]="s1ButtonType.Refresh" [disabled]="applicationPolicy?.packageName || !configurationForm.controls.packageName?.value || configurationForm.controls.packageName?.value.trim().length === 0" (onClick)="loadApplication(configurationForm.controls.packageName.value)"></s1-button>
    <div class="text-danger ml-4" *ngIf="packageNameNotFound">{{'base.ae_package_name_not_found' | translate}}</div>

    <ng-container *ngIf="application">
      <label class="h3 font-weight-bold text-center col-12 mt-2">{{application?.title}}</label>

      <mdm-input-options-select class="col-12 mb-2" [control]="configurationForm.controls.installType" [label]="'aeapplications.list.table.columns.installType'" [required]="true" [options]="installTypeOptions" [bindAllObject]="false"></mdm-input-options-select>

      <mdm-input-options-select class="col-6 mb-2" [control]="configurationForm.controls.autoUpdateMode" [label]="'aeapplications.list.table.columns.autoUpdateMode'" [options]="autoUpdateModeOptions" [bindAllObject]="false"></mdm-input-options-select>
      <s1-input-text class="col-6 mb-2" type="number" [control]="configurationForm.controls.minimunVersionCode" [label]="'aeapplications.list.table.columns.minimunVersionCode'" [labelGrouped]="false"></s1-input-text>

      <mdm-input-options-select class="col-12 mb-2" [control]="configurationForm.controls.defaultPermissionPolicy" [label]="'aeapplications.list.table.columns.defaultPermissionPolicy'" [options]="permissionPolicyOptions" [bindAllObject]="false"></mdm-input-options-select>

      <!-- lista permessi settati -->
      <ng-container *ngFor="let applicationPermissionControl of applicationPermissionsFormArray?.controls; let i = index">
        <mdm-input-options-select *ngIf="applicationPermissionControl.value" class="col-12" [control]="applicationPermissionControl" label="{{'base.override' | translate }} {{applicationPolicy.permissions[i].permissionId}}" [options]="permissionPolicyOptions" [bindAllObject]="false"></mdm-input-options-select>
      </ng-container>

      <!-- form inserimento nuovo permesso -->
      <!-- testo indicativo -->
      <label class="h-6 font-weight-bold col-12 mb-4">{{ 'aeapplications.list.title_permission_subscribe' | translate }}</label>
      <!-- select lista permissions da json -->
      <mdm-input-options-select class="col-12" [control]="configurationForm.controls.newPermissionKey" label="{{'base.override' | translate }} {{'aeapplications.list.table.columns.permission' | translate}}" [options]="applicationPermissionsOptions" [bindAllObject]="false"></mdm-input-options-select>
      <!-- select fissa -->
      <mdm-input-options-select class="col-10" [control]="configurationForm.controls.newPermissionPolicy" [label]="'aeapplications.list.table.columns.newPermissionPolicy'" [options]="permissionPolicyOptions" [bindAllObject]="false"></mdm-input-options-select>
      <s1-button class="col-2 mt-center" [type]="s1ButtonType.Add" [disabled]="!configurationForm.controls.newPermissionPolicy.value || !configurationForm.controls.newPermissionKey.value" (onClick)="addPermission()"></s1-button>

      <!-- managed configurations -->
      <div *ngIf="!!application?.managedProperties" class="d-flex w-100 h-100 flex-column">
        <!-- titolo managed configurations -->
        <div class="text-center col-12 mt-2">
          <label class="h3 font-weight-bold">{{'base.managed_configurations' | translate}}</label>
          <em class="ml-2 fas text-primary" style="cursor: pointer;"
            [ngClass]="showManagedConfigurations ? 'fa-angle-up' : 'fa-angle-down'"
            (click)="onShowManagedConfigurations()"></em>
        </div>

        <div class="d-flex w-100 h-100 flex-column configurations scrollable" [ngClass]="{'visible': showManagedConfigurations}">
          <!-- NEW PROPERTIES MANAGEMENT -->
          <ng-container *ngTemplateOutlet="propertiesNodeManager; context: { $implicit: application?.managedProperties }"></ng-container>
          <!-- END NEW MANAGEMENT -->
        </div>
      </div>

      <div class="col-12 my-4 text-center">
        <s1-button [type]="s1ButtonType.Save" [disabled]="!application || !configurationForm.valid" (onClick)="save()"></s1-button>
      </div>
    </ng-container>

  </div>
</form>

<!-- MANAGED CONFIGURATIONS PROPERTIES ELEMENTS -->

<!-- PROPERTIES NODE MANAGER (HANDLES WHETHER TO DISPLAY A DROPDOWN OR THE ELEMENTS) -->
<ng-template #propertiesNodeManager let-properties let-managerparentposition="pi">
  <!-- If the property has nested properties then a dropdown is displayed, otherwise directly the elments themselves -->
  @for (propertiesNode of properties; track propertiesNode; let i = $index; let last = $last) {
    @if (propertiesNode.type !== 'HIDDEN') {
      <div class="d-flex flex-column property">
        <ng-container
          [ngTemplateOutlet]="propertiesNode.nestedProperties ? managedConfigurationTitle : managedConfigurationContent" 
          [ngTemplateOutletContext]="{ $implicit: propertiesNode, i, pi: managerparentposition }">
        </ng-container>
      </div>
      @if (propertiesNode.type === 'BUNDLE_ARRAY' && propertiesNode.open && last) {
        <ng-container *ngTemplateOutlet="lastArrayBundleElement; context: { $implicit: i }"></ng-container>
      }
    }
  }
</ng-template>

<!-- PROPERTIES NODE TITLE (DROPDOWN ELEMENT) -->
<ng-template #managedConfigurationTitle let-propertiesNode let-position="i" let-titleparentposition="pi">
  <div class="d-flex justify-content-between align-items-center mp-title scrollable" [ngClass]="{'visible': true}">
    <!-- Property title -->
    <div>
      <label class="h5 font-weight-bold mt-3 mb-2">{{propertiesNode.title}} <span *ngIf="propertiesNode.clone">#{{position + 1}}</span></label>
      <span class="fst-italic" [title]="propertiesNode.description">{{propertiesNode.description}}</span>
    </div>
    <!-- Property delete icon -->
    <em *ngIf="propertiesNode.clone" class="ml-2 fa fa-trash text-primary" style="cursor: pointer;"
      (click)="onManagedConfigurationRemove(position, titleparentposition)">
    </em>
    <!-- Property expand icon -->
    <em class="ml-2 fas text-primary" style="cursor: pointer;"
      [ngClass]="propertiesNode.open ? 'fa-angle-up' : 'fa-angle-down'"
      (click)="onManagedConfigurationExpand(propertiesNode)">
    </em>
  </div>

  <ng-container *ngIf="propertiesNode.open">
    <ng-container
      [ngTemplateOutlet]="propertiesNodeManager" 
      [ngTemplateOutletContext]="{ $implicit: propertiesNode.nestedProperties ? propertiesNode.nestedProperties : propertiesNode, pi: position }">
    </ng-container>
  </ng-container>
</ng-template>

<!-- PROPERTIES NODE CONTENT -->
<ng-template #managedConfigurationContent let-propertiesNode>
  <!-- Managed configuration content -->
  <div class="d-flex flex-column mp-config scrollable" [ngClass]="{'visible': true}">
    <!-- se propertiesNode.type è BUNDLE_ARRAY -->
    <ng-container *ngIf="propertiesNode.type === 'BUNDLE_ARRAY_START'">
      <div class=" col-12 mt-3 mb-2 p-2" 
        style="height:15px;background: linear-gradient(to top, #FFFFFF 0%, #EEEEEE 100%);">
      </div>
    </ng-container>

    <!-- se propertiesNode.type è BUNDLE -->
    <ng-container *ngIf="propertiesNode.type === 'BUNDLE'">
      <label class="h4 font-weight-bold  col-12 mt-3 mb-2">
        {{propertiesNode.description}}
      </label>
    </ng-container>

    <!-- se propertiesNode.type è o STRING o HIDDEN -->
    <ng-container *ngIf="propertiesNode.type === 'STRING'">
      <s1-input-textarea class="col-12"
        [label]="propertiesNode.title"
        [style]="propertiesNode.styleNode"
        [tooltip]="propertiesNode.description"
        [defaultValue]="propertiesNode.value !== null ? propertiesNode.value : propertiesNode.defaultValue !== null ? propertiesNode.defaultValue : null"
        (onChange)="onManagedConfigurationChange(propertiesNode, $event)">
      </s1-input-textarea>
    </ng-container>
    <!-- se propertiesNode.type è o INTEGER -->
    <ng-container *ngIf="propertiesNode.type === 'INTEGER'">
      <s1-input-text class="col-12" type="number"
        [label]="propertiesNode.title"
        [labelGrouped]="false"
        [style]="propertiesNode.styleNode"
        [tooltip]="propertiesNode.description"
        [defaultValue]="propertiesNode.value !== null ? propertiesNode.value : propertiesNode.defaultValue !== null ? propertiesNode.defaultValue : null"
        (onChange)="onManagedConfigurationChange(propertiesNode, $event)">
      </s1-input-text>
    </ng-container>
    <!-- se propertiesNode.type è o BOOL -->
    <ng-container *ngIf="propertiesNode.type === 'BOOL'">
      <s1-input-switch class="col-12"
        [label]="propertiesNode.title"
        [grouped]="false"
        [style]="propertiesNode.styleNode"
        [tooltip]="propertiesNode.description"
        [defaultValue]="propertiesNode.value !== null ? propertiesNode.value : propertiesNode.defaultValue !== null ? propertiesNode.defaultValue : null"
        (onToggle)="onManagedConfigurationChange(propertiesNode, $event)">
      </s1-input-switch>
    </ng-container>
    <!-- se propertiesNode.type è o CHOICE -->
    <ng-container *ngIf="propertiesNode.type === 'CHOICE'">
      <mdm-input-options-select class="col-12"
        [label]="propertiesNode.title"
        [options]="propertiesNode.entries"
        [bindAllObject]="false"
        [style]="propertiesNode.styleNode"
        [tooltip]="propertiesNode.description"
        [defaultValue]="propertiesNode.value !== null ? propertiesNode.value : propertiesNode.defaultValue !== null ? propertiesNode.defaultValue : null"
        (onSelectChange)="onManagedConfigurationChange(propertiesNode, $event)"
        (onSelectClear)="onManagedConfigurationChange(propertiesNode, $event)">
      </mdm-input-options-select>
    </ng-container>
    <!-- se propertiesNode.type è o MULTISELECT -->
    <ng-container *ngIf="propertiesNode.type === 'MULTISELECT'">
      <s1-input-multiselect class="col-12"
        [label]="propertiesNode.title"
        [options]="propertiesNode.entries"
        [style]="propertiesNode.styleNode"
        [tooltip]="propertiesNode.description"
        [defaultValue]="propertiesNode.value !== null ? propertiesNode.value : propertiesNode.defaultValue !== null ? propertiesNode.defaultValue : null"
        (onSelect)="onManagedConfigurationChange(propertiesNode, $event)"
        (onSelectAll)="onManagedConfigurationChange(propertiesNode, $event)"
        (onSelectClear)="onManagedConfigurationChange(propertiesNode, $event)">
      </s1-input-multiselect>
    </ng-container>
  </div>
</ng-template>

<!-- Add node for BUNDLE_ARRAY element -->
<ng-template #lastArrayBundleElement let-i>
  <div class="col-12 mt-3 mb-2 p-2 text-center">
    <s1-button class="col-4 col-md-2 mt-center" type="button"
      [type]="s1ButtonType.Add" 
      (onClick)="cloneArrayBundle(i)">
      {{'s1.button.add' | translate}}
    </s1-button>
  </div>
  <div class=" col-12 mt-3 mb-2 p-2"
    style="height:15px;background: linear-gradient(to bottom, #FFFFFF 0%, #EEEEEE 100%);">
  </div>
</ng-template>